<template>
	<div class="df fdc bcf1 oh" style="height: 100%;">
		<general-header title="选择管理企业"></general-header>
		<div class="f1 oa navbar">
			<div class="m0a df fww pt50" style="width: 1200px;">
				<el-alert title="若无企业可选择，请联系企业管理添加您的账户为企业员工并设置权限" type="warning" :closable="false" v-if="admin_user.employees && !admin_user.employees.length"></el-alert>
				<div class="df p20 br5 bcff m10 mb20 bsbb cp fdc" style="width: 280px; height: 158px;" v-for="employee in admin_user.employees" :key="employee.id" @click="onEmployee(employee)">
					<div class="mb30" v-if="employee.shop">
						<h3 class="c6 mb5"><i class="el-icon-office-building"></i> {{employee.shop.name}}</h3>
						<p class="c9 fs12"><i class="el-icon-date"></i> 有效期至：{{employee.shop.expire || '永久'}}</p>
					</div>
					<div class="df aic jcsb mb5">
						<div class="df aic">
							<el-avatar size="small" class="mr5" :src="employee.avatar || 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'"></el-avatar>
							<span class="c6">{{employee.name}}</span>
						</div>
						<el-badge :value="employee.inform" v-if="employee.inform">
							<div class="fs12 cb7a06b"><i class="el-icon-monitor"></i> 进入工作台</div>
						</el-badge>
						<div class="fs12 cb7a06b" v-else><i class="el-icon-monitor"></i> 进入工作台</div>
					</div>
					<div v-if="employee.roles && employee.roles.length">
						<el-tag size="mini" class="mr5 mb5" v-for="role in employee.roles" :key="role.id">{{role.name}}</el-tag>
					</div>
				</div>
				<div class="df p20 br5 bcff m10 mb20 bsbb cp aic jcc" style="width: 280px; height: 158px;" @click="$utils.toRoute('/create')">
					<p class="c6 cb7a06b"><i class="el-icon-plus"></i> 点击创建</p>
				</div>
			</div>
		</div>
		<copy-right></copy-right>
	</div>
</template>

<style>
	.df.p20.br5.bcff.m10.mb20.bsbb.cp:hover {
		box-shadow: 0 0 5px 5px #ccc;
	}
</style>

<script>
	import { mapState, mapActions, mapMutations } from 'vuex';
	import GeneralHeader from '@/components/general-header';
	import CopyRight from '@/components/general-copyright';

	export default {
		components: {
			GeneralHeader,
			CopyRight
		},
		computed: {
			...mapState(['admin_user', 'tab_routes', 'history_routes'])
		},
		methods: {
			...mapActions(['getConfig']),
			...mapMutations(['setTabRoutes']),
			async onEmployee (e) {
				const { employee_id } = this.admin_user;
				if (employee_id === e.id) return this.$router.push({path: '/'});
				this.$confirm(`此操作将切换 ${e.name} 管理 ${e.shop.name || '未知企业'}, 是否继续？`, '切换提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.post(this.$api.URI_CONFIG, {action: 'setEmployee', employee_id: e.id, tabs: this.tab_routes, histories: this.history_routes});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					// 重新获取配置信息
					await this.getConfig();
					// 清除标签路由，并且跳转到主页
					this.setTabRoutes([{name: 'Home', path: '/', fullPath: '/', meta: {title: '工作台'}, close: false}]);
					this.$router.push({path: '/'});
				});
			}
		},
		data() {
			return {
			};
		},
		async created () {

		}
	}
</script>
